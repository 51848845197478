/** 所有页面通用的JS代码端放在这里
 * ...
 */

// pathname 与 中文名字的映射
const pageNameMap = [
    ['【销立方-首页】', '/', '/index', '/index.html'],
    ['销立方-产品系统】', '/product.html'],
    ['【销立方-关于我们页】', '/about.html'],
    ['【销立方-招商加盟页】', '/join.html'],
    ['【销立方-渠道合作页】', '/cooperation.html'],
    ['【销立方-客户案列页】', '/gustomer.html'],
    ['【销立方-论坛动态】', '/article/begin/articleList']
]



console.log(window.location.pathname)
for (let i = 0, pageNameMapLen = pageNameMap.length; i < pageNameMapLen; i++) {
    let handle = pageNameMap[i]
    for (let j = 0, handleLen = handle.length; j < handleLen; j++) {
        if (handle[j] === window.location.pathname) {
            window.pageNamePrefix = handle[0]
        }
    }
}
// console.log(window.pageNamePrefix || '【销立方-未知页面】')



// viewport根px配置
(function() {
    window.onload = window.onresize = () => {
        let clientWidth = document.body.clientWidth;
        // console.log(clientWidth,'clientWidth');
        if (clientWidth >= 900) {
        /* 适配pc页面 最小适配分辨率 12/(20/1920) = 1152 */
            let size = 20 * clientWidth / 1920 + 'px';
            document.documentElement.style.fontSize = size;
        } else {
        /* 淘宝移动端适配解决方案 */
            (function flexible(window, document) {
                let docEl = document.documentElement;
                let dpr = window.devicePixelRatio || 1;
                function setBodyFontSize() {
                    if (document.body) {
                        document.body.style.fontSize = (12 * dpr) + 'px';
                    } else {
                        document.addEventListener('DOMContentLoaded', setBodyFontSize);
                    }
                }
                setBodyFontSize();
                function setRemUnit() {
                    let rem = docEl.clientWidth / 10;
                    docEl.style.fontSize = rem + 'px';
                }
                setRemUnit();
                window.addEventListener('resize', setRemUnit);
                window.addEventListener('pageshow', function(e) {
                    if (e.persisted) {
                        setRemUnit();
                    }
                });
                if (dpr >= 2) {
                    let fakeBody = document.createElement('body');
                    let testElement = document.createElement('div');
                    testElement.style.border = '.5px solid transparent';
                    fakeBody.appendChild(testElement);
                    docEl.appendChild(fakeBody);
                    if (testElement.offsetHeight === 1) {
                        docEl.classList.add('hairlines');
                    }
                    docEl.removeChild(fakeBody);
                }
            })(window, document);
        }
    };
})();
